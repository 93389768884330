import { HeroContentLeft } from '../HeroContentLeft';
import HomePageContent from '../HomePageContent';
import PageShell from '../PageShell';
import ContactUs from './ContactPageContent';
import {rwidth} from '../../constant/Responsive'
// const rwidth = { xxs: "100%", xs: "80%", xl: "66%" };

function ContactPage() {
    return (
        <>
            <ContactUs rwidth={rwidth}></ContactUs>
        </>
    );
}
export default ContactPage;