

export const responsive_breakpoints = {
    xxs:'0em',
    xs: '30em',
    sm: '48em',
    md: '64em',
    lg: '74em',
    xl: '90em',
}
export const  responsive_header_height  = { base: 80, md: 90 };
export const rwidth = {xxs:"100%", xs: "100%", xl: "70%" };
