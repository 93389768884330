import React from 'react';
import logo from './logo.svg';
import {MantineProvider} from '@mantine/core'
import HomePage from './components/HomePage';
import { responsive_breakpoints } from './constant/Responsive';
import { BrowserRouter as Router,Routes ,Route, Link} from 'react-router-dom';
import ContactPage from './components/contact/ContactPage';
import PageShell from './components/PageShell';
import ProductPage from './components/product/ProductPage';
import SpecificProductPage from './components/product/SpecificProductPage';
import product_info from './constant/ProductInfo';
import Retail from './components/product/product-categories/retail/retail';
import WholeSale from './components/product/product-categories/retail/retail';
function App() {
  const route_map = product_info.map((product)=>
    <Route path={"/"+product.url_link} element={<SpecificProductPage product={product}/>} /> 
  )
  return (
    <MantineProvider
    withGlobalStyles 
    withNormalizeCSS 
    theme={{
      breakpoints:responsive_breakpoints
    }}
    >
    <Router>

      <PageShell>
        <Routes>
          <Route path="/"  element={<HomePage/>} />
          <Route path="/home"  element={<HomePage/>} />
          <Route path="/contact" element={<ContactPage/>} />
          <Route path="/product" element={<ProductPage/>}/>
          <Route path="/product" element={<ProductPage/>}/>
          <Route path="/specific-product" element={<SpecificProductPage/>} /> 
          {route_map}
          <Route path="/retail" element={<Retail/>}/>
          <Route path="/wholesale" element={<WholeSale/>}/>

          </Routes>
      </PageShell>
    </Router>
    </MantineProvider>
    
  );
}

export default App;
