import {
    createStyles,
    SimpleGrid,
    rem,
    Container
} from '@mantine/core';
import { responsive_header_height, rwidth } from '../../../../constant/Responsive'
import { Webtitle } from '../../../Webtitle';
import ProductCard from '../.././ProductCard';
import product_info from '../../../../constant/ProductInfo';
import { WebParagraph } from '../../../WebParagraph';

const useStyles = createStyles((theme) => ({
    wrapper: {
        minHeight: 400,
        margin: "auto",
        width: "100%",
        boxSizing: 'border-box',
        borderRadius: theme.radius.md,
    },
    description: {
        color: "#444444",
        maxWidth: rem(300),
        margin:"auto",
        [theme.fn.smallerThan('sm')]: {
            maxWidth: '100%',
        },
    },
    form: {
        backgroundColor: theme.white,
        padding: theme.spacing.xl,
        borderRadius: theme.radius.md,
        boxShadow: theme.shadows.lg,
    },

    input: {
        backgroundColor: theme.white,
        borderColor: theme.colors.gray[4],
        color: theme.black,
        '&::placeholder': {
            color: theme.colors.gray[5],
        },
    },

    inputLabel: {
        color: theme.black,
    },

    control: {
        backgroundColor: "#61ce70",
    },
}));


export default function Retail(prop) {
    const { classes } = useStyles();
    product_info = []
    const update_padding = () => Object.fromEntries(Object.entries(responsive_header_height).map((e) => [e[0], e[1] + 20]))
    return (
        <Container className={classes.wrapper} w={rwidth} maw="100%" pt={update_padding()}>
            <Webtitle title="零售及批發產品"></Webtitle>
            <WebParagraph content="頂級馬來西亞彭亨州勞勿D197貓山王和檳城D200黑刺原隻榴槤鮮果，優質新鮮！加上急凍榴櫣果肉以及榴櫣泥的零售和批發，讓您盡情享受榴槤的美味。不論是即食、製作月餅、烘烤甜品還是製作雪糕的餡料，我們都能滿足您的需求。歡迎預訂，謝謝！急凍榴櫣果肉和榴櫣泥，只需$500起預約送貨。請注意，送貨不包括馬灣、愉景灣、離島區和上樓，也不包含停車場費用。歡迎透過WhatsApp或電話預訂：98312915。如需換貨的詳細條件，請以WhatsApp預訂時列明的條款為準。快來品嚐頂級榴槤的鮮甜滋味吧！">     
            </WebParagraph>
            <SimpleGrid cols={3}  spacing={20} breakpoints={[{ maxWidth: '60em', cols: 1 },  { maxWidth: '100em', cols: 2 }]} p="0" w={prop.rwidth} maw="100%" ml="auto" mr="auto" mt={"lg"}>
            {
                product_info.map((element)=><ProductCard title={element.title} description={element.description} img_list={element.image}></ProductCard>)
            }
            </SimpleGrid>
        </Container>);
}