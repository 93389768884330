import {
    AppShell,
    Footer,
} from '@mantine/core';
import PageHeader from './PageHeader';
import PageFooter from './PageFooter';
function PageShell(props) {
    return (
        <AppShell 
            styles={{
                main: {
                    paddingTop:"0",
                    paddingLeft:"0",
                    paddingRight:"0",
                    height:"fit-content"
                },
            }}
            footer={

                <PageFooter></PageFooter>
            }
            header={
                <PageHeader/>          
            }
        >       
        {props.children}
        </AppShell>
    );
}
export default PageShell;