import frozen_durian_400 from '../images/durianfruitfrozen/mkfrozen_square.jpg'
import frozen_durian_500 from '../images/durianfruitfrozen/mkfrozen2.jpeg'
import d24_durian_puree from '../images/durianPuree/d24Puree.jpg'
import d197_durian_puree from '../images/durianPuree/mkpuree3.jpg'
import d197_durian from '../images/durianfruit/IMG_8586.jpg'
import black_durian from '../images/durianfruit/black3sq.jpg'
import durian_cake from '../images/durian_cake.jpeg'
import moon_cake from '../images/moonCake/mooncake3.jpg'
import mk1 from '../images/moonCake/mcnew1.jpg';
import mk2 from '../images/moonCake/mcn2.jpg';
import mk3 from '../images/moonCake/mcn3.jpg';
import mk4 from '../images/moonCake/mncake3.jpg'
const product_info = [
    {
        title: "超濃貓山王芝士蛋糕",
        description: <><br />馬來西亞頂級貓山王榴槤肉，日本忌廉芝士，法國忌廉，韓國啡糖，意大利巴馬臣芝士<br />6寸：原價598元，特價488元。<br /> 4寸：原價368元，特價328元。 (批發價另議)<br /></>,
        detail_description: <><p>品味至尊，風味絕佳！讓我們向您介紹一款結合了多國風味的獨特美食——馬來西亞頂級貓山王榴槤肉，搭配日本忌廉芝士、法國忌廉、韓國啡糖和意大利巴馬臣芝士。每一口都能感受到濃郁的榴槤香氣，搭配各國頂級食材的組合，為您帶來無與倫比的口感享受。這款絕妙組合絕對不容錯過，讓您的味蕾在這場國際美食之旅中獲得最豪華的享受。
        </p> <p>結合了多國風味的馬來西亞頂級貓山王榴槤肉，日本忌廉芝士、法國忌廉、韓國啡糖和意大利巴馬臣芝士的美食組合，將為您帶來一場無與倫比的味覺盛宴。無論您是榴槤愛好者還是尋求新奇口味的美食家，這款絕妙組合將讓您的味蕾沉浸在無盡的美味之中。讓您一嚐這道獨特美食，感受來自世界各地的精緻滋味，為您的味蕾帶來非凡的享受。</p><p>馬來西亞頂級貓山王榴槤肉，日本忌廉芝士，法國忌廉，韓國啡糖，意大利巴馬臣芝士<br />6寸：原價598元，特價488元。<br /> 4寸：原價368元，特價328元。 <br/>(批發價另議)</p></>,
        image: [durian_cake],
        url_link: "duriancake"
    }
    ,
    {
        title: "馬來西亞頂級黑刺貓山王榴槤冰皮月餅",
        description: <><br />一盒268元，買兩盒免費送貨。<br />(批發價另議)<br /><br /></>,
        detail_description: <><p>讓我們為您介紹一款極致奢華的美食——馬來西亞頂級黑刺貓山王榴槤冰皮月餅。</p> <p>這款月餅結合了頂級的黑刺和貓山王榴槤和綿密的冰皮外層，為您帶來前所未有的口感饗宴。</p> <p>馬來西亞的黑刺和貓山王是世界上最頂級的兩種榴槤，以其濃郁香甜的口感而聞名。 每盒冰皮月餅包括兩款口味，保證您能品嚐到最純正的榴槤風味。</p> <p>冰皮月餅的外層綿密滑順，融合了創新的製作技術，使得每一口都能帶來絕妙的口感體驗。您將感受到榴槤果肉與冰皮的完美結合，給予您舌尖上的極致享受。</p> <p>這款馬來西亞頂級黑刺貓山王榴槤冰皮月餅絕對是中秋佳節的絕佳禮品選擇，也是與家人、朋友共度節日的完美享受。不論是送禮還是品味，這款月餅都能讓您以最精緻的方式感受到馬來西亞頂級美食的魅力。讓我們一同在這個特別的節日中品味來自馬來西亞的頂級黑刺貓山王榴槤冰皮月餅的獨特風味。</p><br />一盒268元，買兩盒免費送貨。每盒6粒裝，黑刺、貓山王各3粒。<br />(批發價另議)<br /><br /></>,
        image: [mk4,mk1, mk2, mk3],
        url_link: "mooncake"
    },
    {
        title: "貓山王鮮果",
        description: <>散買: $90/磅<br />一箱：$1680，毎箱10公斤/22磅，4-6個<br />(批發價另議)<br /><br /></>,
        detail_description: <><p>來自馬來西亞的頂級貓山王榴槤，傳遞甜蜜與新鮮的美味。每一顆經過嚴格挑選的貓山王榴槤都帶著濃郁的香氣和多汁的果肉，讓您在品嚐時彷彿置身於熱帶水果園。無論是單獨食用，還是用於製作各種美食創意，貓山王鮮果都能為您帶來令人驚艷的味覺享受。讓這些頂級榴槤成為您的美味夥伴，為您的生活增添一絲奢華。</p> 散買: $90/磅<br />一箱：$1680，毎箱10公斤/22磅，4-6個<br />(批發價另議)<br /><br /></>,
        image: [d197_durian],
        url_link: "mskfruit"
    }
    ,
    {
        title: "黑刺榴槤鮮果",
        description: <>散買：$90/磅<br />一箱：$1680，毎箱10公斤/22磅，4-6個<br />(批發價另議)<br /><br /></>,
        detail_description: <><p>黑刺榴槤鮮果帶給您的是一場非凡的水果盛宴！來自馬來西亞的黑刺榴槤以其濃郁的果肉、香甜的味道和滑順的質地而聞名。每一口都充滿著黑刺榴槤獨特的風味，讓您陶醉於這種水果的魅力中。不論是獨自享用，還是用於烹飪創作，黑刺榴槤鮮果都能為您帶來無限的驚喜和美味。讓您的味蕾沉浸在黑刺榴槤的魔力中，感受大自然的恩賜。</p>散買：$90/磅<br />一箱：$1680，毎箱10公斤/22磅，4-6個<br />(批發價另議)<br /><br /></>,
        image: [black_durian],
        url_link: "d200fruit"
    }
    ,
    {
        title: "D197貓山王•急凍果肉 400g",
        description: <>每盒 400g<br /> $220/盒<br />(批發價另議)<br /><br /></>,
        detail_description: <><p>品味頂級的D197貓山王榴槤，隨時隨地！我們為您帶來了D197貓山王•急凍果肉，方便您隨時享受這種珍貴水果的美味。每一片果肉都經過嚴格挑選和急凍處理，以確保保持其天然的甜蜜和綿密口感。無論是直接食用還是用於製作冰淇淋、奶昔、果汁等，D197貓山王•急凍果肉都能為您帶來絕佳的味覺享受。現在，您可以隨時品嚐到純正的D197貓山王榴槤的美味！</p>每盒 400g<br /> $220/盒<br />(批發價另議)<br /><br /></>,
        image: [frozen_durian_400],
        url_link: "d197fruit400"

    },
    {
        title: "D197貓山王•急凍果肉 500g",
        description: <>每盒 450g<br /> $270/盒<br />(批發價另議)<br /><br /></>,
        detail_description: <><p>為您提供更多的D197貓山王榴槤美味！我們榮幸地推出了D197貓山王•急凍果肉 500g，讓您能夠盡情享受這種頂級水果的魅力。這500克的急凍果肉為您提供了更多的美味，每一片果肉都保持著D197貓山王榴槤獨特的香甜和豐潤口感。無論是獨自品嚐，與親朋好友分享，還是用於創作各種美食，這份急凍果肉將為您帶來令人難以忘懷的味覺體驗。現在，讓您的味蕾沉浸在D197貓山王榴槤的奇妙世界中吧！</p>每盒 450g<br /> $270/盒<br />(批發價另議)<br /><br /></>,
        image: [frozen_durian_500],
        url_link: "d197fruit500"
    },
    {
        title: "D24蘇丹王泥",
        description: <>每包2kg<br />1-4包: $450/包<br />5-9包: $420/包<br />10包或以上:$360/包 (批發價另議)<br /></>,
        detail_description: <><p>來自馬來西亞的皇室美味！我們自豪地呈獻D24蘇丹王泥，這是一種讓您愛不釋手的榴槤美食。D24蘇丹王榴槤以其濃郁的果肉、濃厚的香氣和柔滑的質地而聞名。我們精心挑選成熟的D24蘇丹王榴槤，將其打成綿密的泥狀，為您帶來絕佳的口感享受。無論是直接品嚐，用於製作冰淇淋、糕點還是其他美食創意，D24蘇丹王泥都能讓您感受到皇室級的味覺享受。讓這款美味的榴槤泥成為您的舌尖上的珍寶！</p>每包2kg<br />1-4包: $450/包<br />5-9包: $420/包<br />10包或以上:$360/包 (批發價另議)<br /></>,

        image: [d24_durian_puree],
        url_link: "d24fruitpuree"

    },
    {
        title: "D197貓山王泥",
        description: <>每包2kg<br />1-4包: $680/包<br />5-9包: $620/包<br />10包或以上:$560/包 (批發價另議)<br /></>,
        detail_description: <><p>D197貓山王榴槤的絕佳味道，現已為您打造成綿密的泥狀！我們誠摯地呈獻D197貓山王泥，將這種頂級榴槤的美味帶到您的餐桌上。我們以最優質的D197貓山王榴槤果肉為基礎，精心打造出這款濃郁的榴槤泥。每一口都散發著D197貓山王榴槤獨特的香甜和細膩口感，讓您彷彿置身於榴槤的芬芳園地。D197貓山王泥可用於直接食用，也可用於烘焙、冰淇淋、奶昔等多種美食製作。無論是為節日慶典增添風味，還是享受自己的小甜蜜，D197貓山王泥都能為您帶來無與倫比的味覺享受。讓這款精緻的貓山王榴槤泥成為您的味蕾之選，感受來自大自然的美味奇蹟！</p>每包2kg<br />1-4包: $680/包<br />5-9包: $620/包<br />10包或以上:$560/包 (批發價另議)<br /></>,
        image: [d197_durian_puree],
        url_link: "d197fruitpuree"
    }
]

export default product_info;