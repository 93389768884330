import {
    Text,
    Center
}  from '@mantine/core';
import { Container } from 'react-bootstrap';
export function WebParagraph(props){
    return (
        <Container style={{marginTop:"20px",width:"100%",maxWidth:"100%"}}>
            <Center >
                <Text  fz={{ xxs: "xs",xs:"sm", sm: "1.01rem" }} style={{lineHeight:3,letterSpacing:2}}>
                    {props.content}
                </Text>
            </Center>
        </Container>
    );
};

