import { useState, useRef } from 'react';
import {
    Header,
    MediaQuery,
    Flex,
    Center,
    useMantineTheme,
    Text,
    Button,
    Image
} from '@mantine/core';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './HomePage.css'
import { responsive_header_height } from '../constant/Responsive'
import Dropdown from './Dropdown'
import companylogo from '../images/logo-min.png'
import { IconPhoneCall, IconPhoneCalling } from '@tabler/icons-react';
import { rwidth } from '../constant/Responsive'
import { NavLink } from 'react-bootstrap';
import CompanyLogo from './CompanyLogo';

let header_nav_background_style = {
    marginRight: '0',
    transition: 'background-color 0.8s ease',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    height: "100%",
    width: "fit-content",
    paddingBottom: "0px",
    minWidth: "100px"
};
const header_title = "猫山王朝"

export default function PageHeader() {
    const theme = useMantineTheme();
    const nav_list = [useRef(null), useRef(null), useRef(null)];
    const nav_border_ref = [useRef(null), useRef(null), useRef(null)];
    const nav_text_ref = [useRef(null), useRef(null), useRef(null)];
    let [nav_active, set_nav_active] = useState([(window.location.pathname == "/home" || window.location.pathname == "/") ? true : false, (window.location.pathname == "/product") ? true : false, (window.location.pathname == "/contact") ? true : false]);
    let current_id = 0;
    const navhover = (id) => {
        let tmp = [false, false, false];
        tmp[id] = true;
        set_nav_active(tmp)

    };
    const navhoverexit = (obj_ref, order_ref, obj_text) => {

        let tmp = [false, false, false];
        tmp[current_id] = true;
        set_nav_active(tmp)
    };
    if (window.location.pathname == "/home" || window.location.pathname == "/") {
        current_id = 0;
    }
    else if (window.location.pathname == "/product") {
        current_id = 2;
    }
    else if (window.location.pathname == "/contact") {
        current_id = 1;
    }
    let nav_style = (nav_active, nav_id) => { return { width: (nav_active[nav_id]) ? "100%" : "0px", borderBottom: "4px solid #ffbf00", transition: 'width 0.4s ease-out' } }

    return (
        <div >
            <Header className="shadow-sm" h={responsive_header_height} p="md" style={{ padding: "0", border: "0px", backgroundColor: "white" }} >
                <Flex direction={"row"} justify={"space-between"} w={rwidth} mx="auto" h={90} px="0" style={{ backgroundColor: "transparent" }}>
                    <CompanyLogo/>
                    <MediaQuery largerThan="md" styles={{ display: 'none' }}>
                        <Center>
                            <Dropdown />
                        </Center>
                    </MediaQuery>
                    <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
                        <Flex direction={"row"} justify={"flex-end"} >
                            <Navbar bg="transparent" data-bs-theme="dark" style={{ width: "fit-content", height: "100%", padding: 0 }}>
                                <Container style={{ height: "100%" }}>
                                    <Nav className="me-auto" style={{ height: "100%" }}>
                                        <div style={header_nav_background_style}>
                                            <Nav.Link href="tel:98312915" style={{ display: "inline-block", height: "fit-content", background: "transparent", marginBottom: false }} >
                                                <Button radius="xl" color={"yellow"}>
                                                    <Text><IconPhoneCall />+852 98312915</Text>
                                                </Button>
                                            </Nav.Link>
                                        </div>
                                        <div ref={nav_list[0]} onMouseEnter={() => navhover(0)} onMouseLeave={() => navhoverexit()} style={header_nav_background_style}  >
                                            <Nav.Link ref={nav_text_ref[0]} href="home" style={{ display: "inline-block", height: "fit-content", background: "transparent", color: (nav_active[0]) ? "#ffbf00" : "#777777" }} >首頁</Nav.Link>
                                            <div ref={nav_border_ref[0]} style={nav_style(nav_active, 0)}></div>
                                        </div>
                                        <div ref={nav_list[1]} onMouseEnter={() => navhover(1)} onMouseLeave={() => navhoverexit()} style={header_nav_background_style} >
                                            <Nav.Link ref={nav_text_ref[1]} href="contact" style={{ display: "inline-block", height: "fit-content", background: "transparent", color: (nav_active[1]) ? "#ffbf00" : "#777777" }}>查詢訂銷</Nav.Link>
                                            <div ref={nav_border_ref[1]} style={nav_style(nav_active, 1)}></div>
                                        </div>
                                        <div ref={nav_list[2]} onMouseEnter={() => navhover(2)} onMouseLeave={() => navhoverexit()} style={header_nav_background_style} >
                                            <Nav.Link ref={nav_text_ref[2]} href="product" style={{ display: "inline-block", height: "fit-content", background: "transparent", color: (nav_active[2]) ? "#ffbf00" : "#777777" }}>零售及批發產品</Nav.Link>
                                            <div ref={nav_border_ref[2]} style={nav_style(nav_active, 2)}></div>
                                        </div>
                                    </Nav>
                                </Container>
                            </Navbar>
                        </Flex>
                    </MediaQuery>
                </Flex>

                {/* <Flex direction={"row"} justify={"end"} w={rwidth} mx="auto" h="100%" px="0" style={{backgroundColor:"#ffbf00"}}>
            <MediaQuery largerThan="md" styles={{ display: 'none' }}>
                <Center>
                <Dropdown  />
                </Center>
             </MediaQuery>
            <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
            <Flex direction={"row"} justify={"flex-end"} >
                <Navbar bg="transparent" data-bs-theme="dark" style={{width:"fit-content",height:"100%",padding:0}}>
                        <Container style={{height:"100%"}}>
                            <Nav className="me-auto" style={{height:"100%"}}>
                                <div style={header_nav_background_style}>
                                <Nav.Link  href="contact"  style={{color:"#333333",height:"fit-content",background:"transparent"}} ><Text><IconPhoneCall/>+852 98312915</Text></Nav.Link>             
                                </div>
                                <div  ref={nav_list[0]}  onMouseEnter={()=>navhover(nav_list[0])} onMouseLeave={()=>navhoverexit(nav_list[0])}   style={header_nav_background_style}  >
                                <Nav.Link  href="home"  style={{color:"#333333",height:"fit-content",background:"transparent"}} >首頁</Nav.Link>             
                                </div>
                                <div  ref={nav_list[1]} onMouseEnter={()=>navhover(nav_list[1])} onMouseLeave={()=>navhoverexit(nav_list[1])}   style={header_nav_background_style} >
                                <Nav.Link  href="contact"  style={{color:"#333333",height:"fit-content",background:"transparent"}}>查詢訂銷</Nav.Link></div>
                                <div ref={nav_list[2]} onMouseEnter={()=>navhover(nav_list[2])} onMouseLeave={()=>navhoverexit(nav_list[2])}   style={header_nav_background_style} >
                                <Nav.Link  href="product"  style={{color:"#333333",height:"fit-content",background:"transparent"}}>零售及批發產品</Nav.Link>
                                </div>
                           </Nav>
                        </Container>
                    </Navbar>  
                </Flex>
            </MediaQuery>
        </Flex> */}

            </Header>
        </div>
    )
}