import { createStyles, Overlay, Container, Title, Button, Text, rem } from '@mantine/core';
import background from '../images/background.jpg';
const useStyles = createStyles((theme) => ({
  hero: {
    position: 'relative',
    backgroundImage:  `url(${background})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width:"100%",
    padding:0
  },
  container: {
    height: rem(650),
    // width:rem(800),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingBottom: `calc(${theme.spacing.xl} * 6)`,
    zIndex: 1,
    position: 'relative',
    margin:0,
    [theme.fn.smallerThan('sm')]: {
      height: rem(500),
      // width:rem(600),
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },
  },
  container_wrapper:{
    height: rem(700),
    // width: rem(1400),
    paddingTop:"2rem",
    display: 'flex',
    flexDirection: 'row',
    justifyContent:"flex-start",

  },
  title: {
    color: theme.white,
    fontSize: rem(60),
    fontWeight: 900,
    lineHeight: 1.1,

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(70),
      lineHeight: 1.2,
    },

    [theme.fn.smallerThan('xs')]: {
      fontSize: rem(50),
      lineHeight: 1.3,
    },
  },

  description: {
    color: theme.grey,
    maxWidth: 600,
    fontWeight:600,
    [theme.fn.smallerThan('sm')]: {
      maxWidth: '100%',
      fontSize: theme.fontSizes.sm,
    },
  },

  control: {
    marginTop: `calc(${theme.spacing.xl} * 1.5)`,

    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },
}));

export function HeroContentLeft(props) {
  const { classes } = useStyles();

  return (
    <div className={classes.hero} >
   
      <Container className={classes.container_wrapper}  w={props.rwidth} maw="100%">
        <Container className={classes.container} >
          <Title className={classes.title}>猫山王朝•為你提供最優質的榴槤產品。</Title>
          <Text className={classes.description} size="xl" mt="xl">
          榴槤鮮果、原料，毎星期新鮮空運到港 ，做餡料、即食、甜品、雪糕、烘焙等一流！ 
          </Text>
        </Container>

      </Container>
     
    </div>
  );
}