import { Menu, Burger, createStyles,MediaQuery } from '@mantine/core';
import Nav from 'react-bootstrap/Nav';

import { useState } from 'react';
const useStyles = createStyles((theme) => ({
    item: {
        '&[data-hovered]': {
            backgroundColor: "rgb(250,250,250)",
            color: theme.grey,
        },
    },
}));


export default function Dropdown(props) {
    const [opened, setOpened] = useState(false);
    const { classes } = useStyles();
    return (
        <Menu classNames={classes} shadow="md" width={"100%"} >
            <Menu.Target>
                 <Burger
                            opened={opened}
                            onClick={() => setOpened((o) => !o)}
                            size={"md"}
                            color={"#f6c915"}
                            mr="xl"
                />
            </Menu.Target>
            <Menu.Dropdown bg="rgb(254,254,254)" shadow="lg" style={{ border: "0px solid transparent" }}>
                <Nav.Link href="home" ><Menu.Item bg="rgb(252,252,252)" color="#777777" >首頁</Menu.Item></Nav.Link>
                <Nav.Link href="contact"><Menu.Item bg="rgb(252,252,252)" color="#777777">查詢訂銷</Menu.Item></Nav.Link>
                <Nav.Link href="product"><Menu.Item bg="rgb(252,252,252)" color="#777777" >零售及批發產品</Menu.Item></Nav.Link>
            </Menu.Dropdown>
        </Menu>
    );
}