import {
    Title,
    Center,
    Container,
    Grid,
    MediaQuery
} from '@mantine/core';
export function Webtitle(props) {
    return (
        <Container w="100%" maw="100%" p="0" style={{ borderBottom: "2px solid #ffbf00" }}   >
            <Center py={"sm"} px={0}  w="100%" maw="100%">
                <Grid  columns={24} justify={"space-between"}  maw="100%" gutter="xs" grow>
                    <MediaQuery smallerThan="xs" styles={{ display: 'none' }}>
                        <Grid.Col span={1} p={0} w={"fit-content"}>
                            <Title fz={{ xxs: "0.95rem", sm: "1.25rem" }} w="100%" align="center">
                                👑
                            </Title>
                        </Grid.Col>
                    </MediaQuery>
                    <Grid.Col span={"auto"} p={0} >
                        <Title style={{whiteSpace:"nowrap"}} fz={{ xxs: "sm",xs:"md", sm: "1.25rem" }} align="center">
                            {props.title}
                        </Title>
                    </Grid.Col>
                    <MediaQuery smallerThan="xs" styles={{ display: 'none' }}>
                        <Grid.Col span={1} p={0} w={"fit-content"}>
                            <Title fz={{ xxs: "0.95rem", sm: "1.25rem" }} w="100%" align="center">
                                👑
                            </Title>
                        </Grid.Col>
                    </MediaQuery>
                </Grid>
            </Center>
        </Container>
    );
};

