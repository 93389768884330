import { createStyles, Container, rem, Flex, Center, Text, Grid, Image } from '@mantine/core';
import { IconBrandWhatsapp, IconBrandFacebook, IconMail, IconPrinter, IconFlag } from '@tabler/icons-react';
import { useRef } from 'react';
import Nav from 'react-bootstrap/Nav';
import companylogo from '../images/logo-min.png';
import CompanyLogo from './CompanyLogo';
const useStyles = createStyles((theme) => ({
    footer: {
        marginTop: rem(0),
        borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
        background: "#ffffff",
        height: "fit-content"
    },
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.spacing.md} ${theme.spacing.md}`,
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },
    links: {
        [theme.fn.smallerThan('sm')]: {
            marginTop: theme.spacing.lg,
            marginBottom: theme.spacing.sm,
        },
    },
}));

const header_title = "猫山王朝";
const footer_width = "12.5rem";
const right_width = "7rem";
/*
Responsive break point:
xxs, xs

*/
export default function PageFooter() {
    const { classes } = useStyles();
    const nav_list = [useRef(null), useRef(null), useRef(null)];
    const navhover = (obj_ref) => {
        // obj_ref.current.style.borderBottom= "3px solid #ffaf00";
    };
    const navhoverexit = (obj_ref) => {
        // obj_ref.current.style.borderBottom= "0px solid rgb(0,0,0,0)";
    };
    return (
        <div className={classes.footer}>
            <Container px={0} py={20} maw="100%">
                <Grid  w={{ xxs: "100%", md: "71rem",lg:"80rem" }} justify={"center"} maw="100%" mx="auto" mt={"lg"}>
                    <Grid.Col span={12} md={3}>
                    <CompanyLogo/>

                    </Grid.Col>
                    <Grid.Col  span={12} md={6} p={0}>
                        <Grid  w={{xxs:footer_width,xs:"34rem"}} maw="100%" mx={"auto"} mt={{xxs:"1rem",md:"0"}}>
                            <Grid.Col span={12} xs={6} p={0}>
                                <Flex direction={"row"} justify={{xxs:"start",xs:"end"}} w={"100%"}>
                                    <Flex w={footer_width} direction="row" maw={"30rem"} h={"fit-content"} gap={4} wrap="wrap">
                                        <Container px="0" w={footer_width} onMouseEnter={() => navhover(nav_list[0])} onMouseLeave={() => navhoverexit(nav_list[0])}>
                                        <Text style={{ color: "#f6c915",fontSize:"1.2rem",fontWeight:3000}}>聯絡我們</Text>
                                        </Container>
                                        <Container px="0" w={footer_width}>
                                            <Text style={{ color: "#777777" }}><IconBrandWhatsapp></IconBrandWhatsapp> +852 98312915</Text>
                                        </Container>
                                        <Container px="0" w={footer_width}>
                                            <Text style={{ color: "#777777" }}><IconMail></IconMail> hangtatmsk@gmail.com</Text>
                                        </Container>
                                        <Container px="0" w={footer_width}>
                                            <Text style={{ color: "#777777" }}><IconPrinter></IconPrinter> 81470126</Text>
                                        </Container>
                                        <Container px="0" w={footer_width}>
                                            <Nav.Link href="https://www.facebook.com/pages/category/Food-Wholesaler/%E8%B2%93%E5%B1%B1%E7%8E%8B%E6%9C%9D-387798108690714" style={{ color: "#777777" }}> <IconBrandFacebook></IconBrandFacebook> 貓山王朝</Nav.Link>
                                        </Container>
                                    </Flex>
                                </Flex>
                            </Grid.Col>
                            <Grid.Col span={12} xs={6} p={0}>
                                <Flex direction={"row"} justify={{xxs:"start",xs:"end"}} w={"100%"}>
                                    <Flex w={footer_width} direction="row" maw={"30rem"} h={"fit-content"} gap={4} wrap="wrap">
                                        <Container px="0" w={footer_width} onMouseEnter={() => navhover(nav_list[0])} onMouseLeave={() => navhoverexit(nav_list[0])}>
                                        <Text style={{ color: "#f6c915",fontSize:"1.2rem",fontWeight:3000}}>了解更多</Text>
                                        </Container>
                                        <Container px="0" w={footer_width} onMouseEnter={() => navhover(nav_list[0])} onMouseLeave={() => navhoverexit(nav_list[0])}>
                                            <Nav.Link ref={nav_list[0]} href="home" style={{ color: "#777777" }}>首頁</Nav.Link>
                                        </Container>
                                        <Container px="0" w={footer_width} onMouseEnter={() => navhover(nav_list[1])} onMouseLeave={() => navhoverexit(nav_list[1])}>
                                            <Nav.Link ref={nav_list[1]} href="contact" style={{ color: "#777777" }}>查詢訂銷</Nav.Link>
                                        </Container>                                       
                                        <Container px="0" w={footer_width} onMouseEnter={() => navhover(nav_list[2])} onMouseLeave={() => navhoverexit(nav_list[2])}>
                                            <Nav.Link ref={nav_list[2]} href="product" style={{ color: "#777777" }}>零售及批發產品</Nav.Link>
                                        </Container>
                                    </Flex>
                                </Flex>
                            </Grid.Col>
                        </Grid>
                    </Grid.Col>

                </Grid>

            </Container>

        </div>
    );
}