import { MediaQuery,Image,Center } from "@mantine/core";
import { Nav } from "react-bootstrap";
import companylogo from '../images/logo-min.png';

export default function  CompanyLogo(){
    return (
        <Center>
            <Nav.Link href="/home">
                <MediaQuery smallerThan={"md"} styles={{ display: 'none' }}>
                    <Image
                        width={140}
                        ml={30}
                        src={companylogo}>
                    </Image>
                </MediaQuery>
                <MediaQuery largerThan={"md"} styles={{ display: 'none' }}>
                    <Image
                        width={100}
                        ml={30}
                        src={companylogo}>
                    </Image>
                </MediaQuery>
            </Nav.Link>
        </Center>);
}