import { HeroContentLeft } from './HeroContentLeft';
import HomePageContent from './HomePageContent';
import PageShell from './PageShell';
import {rwidth} from '../constant/Responsive'

function HomePage() {
    return (
        <>
            <HeroContentLeft rwidth={rwidth} />
            <HomePageContent rwidth={rwidth}></HomePageContent>
        </>
            
    );
}
export default HomePage;