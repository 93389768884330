import { Card, Image, Center} from '@mantine/core';
import homepageImage1 from '../images/logodurian.jpg';

export default function DemoCard(props) {
  return (
    <Card shadow="xxs" padding="lg" radius="md" >
      <Card.Section component="a" >
        <Center>
          <Image
            src={props.src}
            maw={400}
            // height={600}
            fit={props.contain_mode}
            // width={props.width}
            // fit="contain"
            alt="Durian"
          />
        </Center>

      </Card.Section>
    </Card>
  );
}